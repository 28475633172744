import React, { memo } from 'react';
import classNames from 'classnames';

import NavigationItem from './NavigationItem/NavigationItem';
import NavigationToggle from './NavigationToggle/NavigationToggle';

import * as styles from './Navigation.module.scss';

const defaultNav = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'News',
    url: '/news',
  },
  {
    name: 'Donate',
    url: '/donate',
  },
  {
    name: 'About',
    url: '/about',
  },
  {
    name: 'Contact',
    url: '/contact',
  },
];

const Navigation = ({
  isNavOpen,
  location,
  navigation = defaultNav,
  toggleNav,
}) => (
  <div className={styles.wrapper}>
    <NavigationToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />

    <nav className={classNames(styles.menu)}>
      <div className={styles.header}></div>
      <ul className={styles.list}>
        {navigation.map((item) => (
          <NavigationItem
            key={item.name}
            path={item.url}
            title={item.name}
            toggleNav={toggleNav}
            type={item.type}
          />
        ))}
      </ul>
    </nav>
  </div>
);

export default memo(Navigation);

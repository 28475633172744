import React from 'react';
import { Link } from 'gatsby';

import * as styles from './NavigationItem.module.scss';

const NavigationItem = ({ path, title, toggleNav, type }) => (
  <li className={styles.wrapper}>
    {type === 'external' ? (
      <a
        className={styles.link}
        href={path}
        onClick={() => toggleNav()}
        target="_blank"
      >
        {title}
      </a>
    ) : (
      <Link
        className={styles.link}
        onClick={(event) => {
          toggleNav();
        }}
        to={path}
      >
        {title}
      </Link>
    )}
  </li>
);

export default NavigationItem;

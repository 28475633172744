import * as React from 'react';
import { Link } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import Navigation from '../Navigation/Navigation';

import * as styles from './SiteHeader.module.scss';

const SiteHeader = ({ isNavOpen, logo, navigation, title, toggleNav }) => (
  <header className={styles.wrapper}>
    <div className={styles.container}>
      <Link className={styles.homeLink} to="/">
        <GatsbyImage
          alt={`${title} logo`}
          className={styles.logo}
          image={getImage(logo)}
        />
        <h1 className={styles.header}>{title}</h1>
      </Link>
      <span className={styles.separator}></span>
      <div className={styles.navigation}>
        <div className={styles.navigationWrapper}>
          <Navigation
            isNavOpen={isNavOpen}
            navigation={navigation}
            toggleNav={toggleNav}
          />
        </div>
      </div>

      <Link className={styles.donate} to="/donate">
        Donate
      </Link>
    </div>
  </header>
);

export default SiteHeader;

import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import icons from '../../images/svg/social.svg';

import * as styles from './Social.module.scss';

const Social = ({ email, facebook, instagram, twitter }) => (
  <section className={styles.wrapper}>
    <div className={styles.container}>
      <a
        className={classNames(styles.link, styles.email)}
        href={`mailto:${email}`}
      >
        <div className={styles.icon} />
      </a>
      <a className={classNames(styles.link, styles.facebook)} href={facebook}>
        <div className={styles.icon} />
      </a>
      <a className={classNames(styles.link, styles.twitter)} href={twitter}>
        <div className={styles.icon} />
      </a>
      <a className={classNames(styles.link, styles.instagram)} href={instagram}>
        <div className={styles.icon} />
      </a>
    </div>
  </section>
);

export default Social;

import * as React from 'react';
import { Link } from 'gatsby';

import Social from '../../components/Social/Social';

import * as styles from './SiteFooter.module.scss';

const SiteFooter = ({
  charity,
  copyright,
  email,
  facebook,
  instagram,
  twitter,
}) => (
  <footer className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.info}>
        <p>
          © {new Date().getFullYear()} {copyright}
        </p>
        <p>
          Registered Charity <strong>{charity}</strong>
        </p>
      </div>
      <div className={styles.social}>
        <Social
          email={email}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </div>
    </div>
  </footer>
);

export default SiteFooter;

import React, { useState } from 'react';
import className from 'classnames';
import { useStaticQuery, Link, graphql } from 'gatsby';

import FacebookWidget from '../FacebookWidget/FacebookWidget';
import SiteHeader from '../SiteHeader/SiteHeader';
import SiteFooter from '../SiteFooter/SiteFooter';
import SideBar from '../SideBar/SideBar';
import SubNavigation from '../SubNavigation/SubNavigation';

import * as styles from './PageLayout.module.scss';

const PageLayout = ({ location, children }) => {
  const {
    settingsYaml: { logo, navigation, title, ...footerSettings },
  } = useStaticQuery(
    graphql`
      query {
        settingsYaml(section: { eq: "site" }) {
          charity
          copyright
          email
          facebook
          instagram
          logo {
            childImageSharp {
              gatsbyImageData(width: 180, formats: [AUTO, WEBP, AVIF])
            }
          }
          navigation {
            name
            type
            url
          }
          title
          twitter
        }
      }
    `
  );

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => setIsNavOpen(!isNavOpen);

  const handleClose = () => {
    setIsNavOpen(false);
  };

  return (
    <div className={className(styles.wrapper, isNavOpen && styles.nav)}>
      <SiteHeader
        isNavOpen={isNavOpen}
        logo={logo}
        navigation={navigation}
        title={title}
        toggleNav={toggleNav}
      />

      <div className={styles.container}>{children}</div>
      <SiteFooter {...footerSettings} />
    </div>
  );
};

export default PageLayout;
